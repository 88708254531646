import packageJSON from "../../package.json"

const categoriesData = {
    categories: {
        brands: {
            name: 'Бренды',
            route: '/brands',
            imageUrl: `${packageJSON.homepage}/images/categories/brands.webp`,
            subcategories: [
                {
                    name: 'Anfen',
                    metaDataKey: 'Anfen',
                    route: '/brands/anfen',
                    imageUrl: `${packageJSON.homepage}/images/brands/Anfen.webp`,
                },
                {
                    name: 'Diorella',
                    metaDataKey: 'Diorella',
                    route: '/brands/diorella',
                    imageUrl: `${packageJSON.homepage}/images/brands/Diorella.webp`,
                },
                {
                    name: 'Finikin',
                    metaDataKey: 'Finikin',
                    route: '/brands/finikin',
                    imageUrl: `${packageJSON.homepage}/images/brands/Finikin.webp`,
                },
                {
                    name: 'FNNISS',
                    metaDataKey: 'FNNISS',
                    route: '/brands/fniss',
                    imageUrl: `${packageJSON.homepage}/images/brands/FNNISS.webp`,
                },
                {
                    name: 'Lanny mode',
                    metaDataKey: 'Lanny mode',
                    route: '/brands/lanny-mode',
                    imageUrl: `${packageJSON.homepage}/images/brands/Lanny mode.webp`,
                },
                {
                    name: 'Venus',
                    metaDataKey: 'Venus',
                    route: '/brands/venus',
                    imageUrl: `${packageJSON.homepage}/images/brands/Venus.webp`,
                },
                {
                    name: 'Acousma',
                    metaDataKey: 'Acousma',
                    route: '/brands/acousma',
                    imageUrl: `${packageJSON.homepage}/images/brands/Acousma.webp`,
                },
                {
                    name: 'Anttifu',
                    metaDataKey: 'Anttifu',
                    route: '/brands/anttifu',
                    imageUrl: `${packageJSON.homepage}/images/brands/ANTTIFU.webp`,
                },
                {
                    name: 'Annajolly',
                    metaDataKey: '',
                    route: '/brands/annajolly',
                    imageUrl: `${packageJSON.homepage}/images/brands/Annajolly.webp`,
                },
                {
                    name: 'Beisdanna',
                    metaDataKey: 'Beisdanna',
                    route: '/brands/beisdanna',
                    imageUrl: `${packageJSON.homepage}/images/brands/Beisdanna.webp`,
                },
                {
                    name: 'Biweier',
                    metaDataKey: 'Biweier',
                    route: '/brands/biweier',
                    imageUrl: `${packageJSON.homepage}/images/brands/Biweier.webp`,
                },
                {
                    name: 'Blue Rose',
                    metaDataKey: 'Blue Rose',
                    route: '/brands/blue-rose',
                    imageUrl: `${packageJSON.homepage}/images/brands/Blue Rose.webp`,
                },
                {
                    name: 'Calvin Klein',
                    metaDataKey: 'Calvin Klein',
                    route: '/brands/calvin-klein',
                    imageUrl: `${packageJSON.homepage}/images/brands/Calvin Klein.webp`,
                },
                {
                    name: 'Coeur Joie',
                    metaDataKey: 'Coeur Joie',
                    route: '/brands/coeur-joie',
                    imageUrl: `${packageJSON.homepage}/images/brands/COEUR JOIE.webp`,
                },
                {
                    name: 'Diana',
                    metaDataKey: 'Diana',
                    route: '/brands/diana',
                    imageUrl: `${packageJSON.homepage}/images/brands/diana-grace-lingerie.webp`,
                },
                {
                    name: 'Donafen',
                    metaDataKey: 'Donafen',
                    route: '/brands/donafen',
                    imageUrl: `${packageJSON.homepage}/images/brands/Donafen.webp`,
                },
                {
                    name: 'FINETOO',
                    metaDataKey: 'FINETOO',
                    route: '/brands/finetoo',
                    imageUrl: `${packageJSON.homepage}/images/brands/FINETOO lingerie.webp`,
                },
                {
                    name: 'FUBA.vi',
                    metaDataKey: 'FUBA.vi',
                    route: '/brands/fuba-vi',
                    imageUrl: `${packageJSON.homepage}/images/brands/FUBA.vi.webp`,
                },
                {
                    name: 'Guo. L.Y',
                    metaDataKey: 'Guo. L.Y',
                    route: '/brands/guo-l-y',
                    imageUrl: `${packageJSON.homepage}/images/brands/GU•L•Y.webp`,
                },
                {
                    name: 'HY',
                    metaDataKey: 'HY',
                    route: '/brands/hy',
                    imageUrl: `${packageJSON.homepage}/images/brands/HY.webp`,
                },
                {
                    name: 'Indena',
                    metaDataKey: 'Indena',
                    route: '/brands/indena ',
                    imageUrl: `${packageJSON.homepage}/images/brands/Indena.webp`,
                },
                {
                    name: 'Kable',
                    metaDataKey: 'Kable',
                    route: '/brands/kable',
                    imageUrl: `${packageJSON.homepage}/images/brands/KABLE.webp`,
                },
                {
                    name: 'Kesell',
                    metaDataKey: 'Kesell',
                    route: '/brands/kesell',
                    imageUrl: `${packageJSON.homepage}/images/brands/KESELL.webp`,
                },
                {
                    name: 'Lavivas',
                    metaDataKey: 'Lavivas',
                    route: '/brands/lavivas',
                    imageUrl: `${packageJSON.homepage}/images/brands/Lavivas.webp`,
                },
                {
                    name: 'Lavolle',
                    metaDataKey: 'Lavolle',
                    route: '/brands/lavolle',
                    imageUrl: `${packageJSON.homepage}/images/brands/LAVOLLE.webp`,
                },
                {
                    name: 'Lue rrose',
                    metaDataKey: 'Lue rrose',
                    route: '/brands/lue-rrose',
                    imageUrl: `${packageJSON.homepage}/images/brands/Lue rrose.webp`,
                },
                {
                    name: 'LuLoLa',
                    metaDataKey: 'LuLoLa',
                    route: '/brands/lulola',
                    imageUrl: `${packageJSON.homepage}/images/brands/LuLoLa.webp`,
                },
                {
                    name: 'Masdana',
                    metaDataKey: 'Masdana',
                    route: '/brands/masdana',
                    imageUrl: `${packageJSON.homepage}/images/brands/Masdana.webp`,
                },
                {
                    name: 'Milasi',
                    metaDataKey: 'Milasi',
                    route: '/brands/milasi',
                    imageUrl: `${packageJSON.homepage}/images/brands/Milasi.webp`,
                },
                {
                    name: 'Milavitsa',
                    metaDataKey: 'Milavitsa',
                    route: '/brands/milavitsa',
                    imageUrl: `${packageJSON.homepage}/images/brands/milavitsa.webp`,
                },
                {
                    name: 'Nadizi',
                    metaDataKey: 'Nadizi',
                    route: '/brands/nadizi',
                    imageUrl: `${packageJSON.homepage}/images/brands/NADIZI.webp`,
                },
                {
                    name: 'Porlloma',
                    metaDataKey: '',
                    route: '/brands/porlloma',
                    imageUrl: `${packageJSON.homepage}/images/brands/Porlloma.webp`,
                },
                {
                    name: 'S·Kaifei',
                    metaDataKey: 'S·Kaifei',
                    route: '/brands/skaifei',
                    imageUrl: `${packageJSON.homepage}/images/brands/S·Kaifei.webp`,
                },
                {
                    name: 'Weiyesi',
                    metaDataKey: 'Weiyesi',
                    route: '/brands/weiyesi',
                    imageUrl: `${packageJSON.homepage}/images/brands/Weiyesi.webp`,
                },
                {
                    name: 'Yenvy',
                    metaDataKey: 'Yenvy',
                    route: '/brands/yenvy',
                    imageUrl: `${packageJSON.homepage}/images/brands/Yenvy.webp`,
                },
                {
                    name: 'Люся',
                    metaDataKey: 'Люся',
                    route: '/brands/lusya',
                    imageUrl: `${packageJSON.homepage}/images/brands/Люся.webp`,
                },
            ]
        },
        bras: {
            name: 'Бюстгальтеры',
            filters: ['bandSize', 'cupSize'],       
            route: '/bras',
            imageUrl: `${packageJSON.homepage}/images/categories/bras.webp`,
            subcategories: [
                {
                    name: 'С поролоном',
                    metaDataKey: 'С поролоном',
                    route: '/bras/foam',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/foam.webp`,
                },
                {
                    name: 'Пуш-ап',
                    metaDataKey: 'Пуш-ап',
                    route: '/bras/push-up',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/push-up.webp`,
                },
                {
                    name: 'Полупоролон',
                    metaDataKey: 'Полупоролон',
                    route: '/bras/semi-foam',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/semi-foam.webp`,
                },
                {
                    name: 'Тонкий поролон',
                    metaDataKey: 'Тонкий поролон',
                    route: '/bras/thin-foam',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/thin-foam.webp`,
                },
                {
                    name: 'Без косточек',
                    metaDataKey: 'Без косточек',
                    route: '/bras/boneless',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/boneless.webp`,
                },
                {
                    name: 'Без поролона',
                    metaDataKey: 'Без поролона',
                    route: '/bras/no-foam',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/no-foam.webp`,
                },
                {
                    name: 'Для кормления',
                    metaDataKey: 'Для кормления',
                    route: '/bras/nursing',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/nursing.webp`,
                },
                {
                    name: 'Бюстье',
                    metaDataKey: 'Бюстье',
                    route: '/bras/bustier',
                    sizeTable: "bras",
                    imageUrl: `${packageJSON.homepage}/images/bras/bustier.webp`,
                },
            ]
        },
        knickers: {
            name: 'Трусики',
            filters: ['size'],  
            route: '/knickers',
            imageUrl: `${packageJSON.homepage}/images/categories/knickers.webp`,
            subcategories: [
                {
                    name: 'Бикини',
                    metaDataKey: 'Бикини',
                    route: '/knickers/bikini',
                    sizeTable: "knickers",
                    imageUrl: `${packageJSON.homepage}/images/knickers/bikini.webp`,
                },
                {
                    name: 'Бесшовные',
                    metaDataKey: 'Бесшовные',
                    route: '/knickers/bodyshort',
                    sizeTable: "knickers",
                    imageUrl: `${packageJSON.homepage}/images/knickers/bodyshort.webp`,
                },
                {
                    name: 'Бразильяны',
                    metaDataKey: 'Бразильяны',
                    route: '/knickers/brazilian',
                    sizeTable: "knickers",
                    imageUrl: `${packageJSON.homepage}/images/knickers/brazilian.webp`,
                },
                {
                    name: 'Норма, Слипы',
                    metaDataKey: 'Норма, Слипы',
                    route: '/knickers/brief',
                    sizeTable: "knickers",
                    imageUrl: `${packageJSON.homepage}/images/knickers/brief.webp`,
                },
                {
                    name: 'Стринги',
                    metaDataKey: 'Стринги',
                    route: '/knickers/g-string',
                    sizeTable: "knickers",
                    imageUrl: `${packageJSON.homepage}/images/knickers/g-string.webp`,
                },
                {
                    name: 'Батальные',
                    metaDataKey: 'Батальные',
                    sizeTable: "knickers",
                    route: '/knickers/hipster',
                    imageUrl: `${packageJSON.homepage}/images/knickers/hipster.webp`,
                },
                {
                    name: 'Шорты/Панталоны',
                    metaDataKey: 'Шорты/Панталоны',
                    sizeTable: "knickers",
                    route: '/knickers/shorts-trousers',
                    imageUrl: `${packageJSON.homepage}/images/knickers/shorts-trousers.webp`,
                },
                {
                    name: 'Корректирующее',
                    metaDataKey: 'Корректирующее',
                    sizeTable: "knickers",
                    route: '/knickers/shapewear',
                    imageUrl: `${packageJSON.homepage}/images/knickers/shapewear.webp`,
                },
            ]
        },
        erotic: {
            name: 'Эротичекое',
            route: '/erotic',
            imageUrl: `${packageJSON.homepage}/images/categories/erotic.webp`,
            subcategories: [
                {
                    name: 'Пеньюары',
                    metaDataKey: 'Пеньюары',
                    route: '/erotic/peignoir',
                    sizeTable: "peignoir",
                    imageUrl: `${packageJSON.homepage}/images/erotic/peignoir.webp`,
                },
                {
                    name: 'Пояса',
                    metaDataKey: 'Пояса',
                    route: '/erotic/belts',
                    sizeTable: "belts",
                    imageUrl: `${packageJSON.homepage}/images/erotic/belts.webp`,
                },
                {
                    name: 'Маски',
                    metaDataKey: 'Маски',
                    route: '/erotic/masks',
                    sizeTable: "masks",
                    imageUrl: `${packageJSON.homepage}/images/erotic/masks.webp`,
                },
                {
                    name: 'Бралье',
                    metaDataKey: 'Бралье',
                    route: '/erotic/braille',
                    sizeTable: "braille",
                    imageUrl: `${packageJSON.homepage}/images/erotic/braille.webp`,
                },
            ]
        },
        swimwear: {
            name: 'Пляж',
            route: '/beach',
            imageUrl: `${packageJSON.homepage}/images/categories/beach.webp`,
            subcategories: [
                {
                    name: 'Женское (Пляж)',
                    metaDataKey: 'Женское (Пляж)',
                    route: '/beach/feminine',
                    imageUrl: `${packageJSON.homepage}/images/beach/feminine.webp`,
                },
                {
                    name: 'Детское (Пляж)',
                    metaDataKey: 'Детское (Пляж)',
                    route: '/beach/baby',
                    imageUrl: `${packageJSON.homepage}/images/beach/baby.webp`,
                },
                {
                    name: 'Мужское (Пляж)',
                    metaDataKey: 'Мужское (Пляж)',
                    route: '/beach/male',
                    imageUrl: `${packageJSON.homepage}/images/beach/male.webp`,
                },
            ]
        },
    },
    uncategorizedSubcategories: {
        sportsBra: {
            name: 'Топики',
            metaDataKey: 'Топики',
            route: '/sports-bra',
            imageUrl: `${packageJSON.homepage}/images/categories/sports-bra.webp`,
        },
        underwearSet: {
            name: 'Комплекты',
            metaDataKey: 'Комплекты',
            route: '/underwear-set',
            imageUrl: `${packageJSON.homepage}/images/categories/underwear-set.webp`,
        },
        male: {
            name: 'Мужское',
            metaDataKey: 'Мужское',
            route: '/male',
            imageUrl: `${packageJSON.homepage}/images/categories/male.webp`,
        },
        teenager: {
            name: 'Подростковое',
            metaDataKey: 'Подростковое',
            route: '/teenager',
            imageUrl: `${packageJSON.homepage}/images/categories/teenager.webp`,
        },
        wedding: {
            name: 'Свадебное',
            metaDataKey: 'Свадебное',
            route: '/wedding',
            imageUrl: `${packageJSON.homepage}/images/categories/wedding.webp`,
        },
        accessories: {
            name: 'Аксессуары',
            metaDataKey: 'Аксессуары',
            route: '/accessories',
            imageUrl: `${packageJSON.homepage}/images/categories/accessories.webp`,
        },
        other: {
            name: 'Разное',
            metaDataKey: 'Разное',
            route: '/other',
            imageUrl: `${packageJSON.homepage}/images/categories/other.webp`,
        },
        sale: {
            name: 'Распродажа',
            metaDataKey: 'Распродажа',
            route: '/sale',
            imageUrl: `${packageJSON.homepage}/images/categories/sale.webp`,
        },
    }
}

export default categoriesData
