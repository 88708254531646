const alertsData = {
    serverRequestFailed: "К сожалению, произошла ошибка при попытке связаться с сервером.",
    missingProducts: "Похоже, что на этой странице пока нет товаров.",
    noSuchId: "Похоже, что такого товара не существует или он был удален.",
    invalidRemains: "Похоже, что данные о товаре заполнены некорректно. " +
        "Если вас заинтересовал данный товар, свяжитесь с нами.",
    cartIsEmpty: "В корзине нет товаров.",
    wishlistIsEmpty: "В списке желаний нет товаров.",
    cartIsFull: "Корзина переполнена.",
    orderIsFull: "Похоже, что сумма товаров в корзине и в необработанном заказе больше допустимой."
}

export default alertsData
