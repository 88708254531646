import React from "react";

const Sale = () => {
    return <div style={{
        position: "absolute",
        top: "1%",
        right: "2%",
        background: "red",
        color: "white",
        padding: "10px 8px",
        borderRadius: "50%"
    }}
    >
        Sale
    </div>
}

export default Sale
